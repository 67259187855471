export default function hijriFormatter(date = new Date()) {
  const options: Intl.DateTimeFormatOptions = {
    calendar: "islamic-umalqura",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  const format = new Intl.DateTimeFormat("id", options);

  var months = [
    "Muharram",
    "Safar",
    "Rabi'ul Awal",
    "Rabi'ul Akhir",
    "Jumadil Awal",
    "Jumadil Akhir",
    "Rajab",
    "Sya'ban",
    "Ramadhan",
    "Syawal",
    "Dzulqaidah",
    "Dzulhijjah",
  ];

  var result = format.format(date).split("/");

  result[1] = months[parseInt(result[1]) - 1];

  return result.join(' ')
}
