
export default function bilang20(date = new Date()) {
    var _date = date.setHours(0, 0, 0, 0), // set hours to midnight
        ms1d = 864e5, // miliseconds in a day
        start = 946656e6, // app start count date in 01 jan 2000
        bilangStr = ['Hunga-hunga', "Talettu'", "Anga'", "Hebbo'", 'Hage', 'Cempa', 'Tulle', 'Arien', 'Biruku', 'Panirong', 'Mahua', 'Dattia', 'Soma', 'Langkara', 'Juppati', 'Tumpa', 'Pon', "Pa'an", 'Gumaha', "Haji'in",]

    // console.log((_date - start) / ms1d % 20)
    return bilangStr[(_date - start) / ms1d % 20]
}


