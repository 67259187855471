import { useState } from "react";
import { format } from "date-fns";
import { id } from "date-fns/locale";

import bilang20 from "../libs/getBilang20Name";
import hijriFormatter from "../libs/hijriFormatter";
import { isEssoAssibokoreng } from "../libs/isEssoAssibokoreng";

const HomePage: React.FC = () => {
  const initDate = new Date().setHours(0, 0, 0, 0);

  const [date, setDate] = useState(initDate);

  const isToday = !!(date === initDate);

  return (
    <div>
      <div className="text-center text-2xl w-full px-4 font-semibold uppercase fixed top-0">
        <label
          htmlFor="date-picker"
          className="border-b-2 border-teal-600 text-gray-700 h-16 flex items-center justify-center"
        >
          {format(date, "dd MMMM uuuu", {
            locale: id,
          })}
        </label>
      </div>
      <div className="flex justify-center noselect">
        <button
          className="w-12 h-12 flex items-center justify-center rounded-r-full bg-teal-600 active:bg-teal-500 text-white fixed left-0 top-center"
          onClick={() => setDate(date - 864e5)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
            />
          </svg>
        </button>

        <div className="flex flex-col items-center justify-center h-screen pt-16 relative">
          <div className="flex flex-col items-center relative">
            {isEssoAssibokoreng(
              format(date, "cccc", {
                locale: id,
              }),
              hijriFormatter(new Date(date)).split(" ")[0]
            ) && (
              <div className=" text-red-500 flex gap-2 text-sm items-center border border-red-500 px-2 py-1 rounded-lg absolute -top-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
                Esso Assibokoreng
              </div>
            )}
            <div
              className={`${
                isToday ? "bg-teal-600" : "bg-gray-500"
              } w-36 py-1 text-center text-white font-bold uppercase rounded`}
            >
              {format(date, "cccc", {
                locale: id,
              })}
            </div>
            <div
              className={`${
                isToday ? "text-gray-700" : "text-gray-500"
              } text-9xl font-black w-36 text-center border-b-2 pb-2 mb-2`}
            >
              {format(date, "dd", {
                locale: id,
              })}
            </div>
            <div className="text-teal-600 text-2xl font-bold text-center uppercase">
              {bilang20(new Date(date))}
            </div>

            {/* Hijri Calendar */}
            <div
              className={`${
                isToday ? "text-teal-600" : "text-gray-500"
              } text-2xl mt-8`}
            >
              — {hijriFormatter(new Date(date))} —
            </div>
          </div>
        </div>
        <button
          className="w-12 h-12 flex items-center justify-center rounded-l-full bg-teal-600 active:bg-teal-500 text-white fixed right-0 top-center"
          onClick={() => setDate(date + 864e5)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
            />
          </svg>
        </button>

        <input
          className="fixed inset-x-0 top-0 h-0"
          onChange={(event) =>
            setDate(new Date(event.target.value).setHours(0, 0, 0, 0))
          }
          id="date-picker"
          type="date"
        />
      </div>
    </div>
  );
};

export default HomePage;
