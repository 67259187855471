export function isEssoAssibokoreng(day: string, hijriDate: string) {
  const lastDateNumber = hijriDate.toString().charAt(hijriDate.length - 1);

  switch (day.toLocaleUpperCase()) {
    case "SENIN":
      if (lastDateNumber === "4") return true;
      break;
    case "SELASA":
      if (lastDateNumber === "3") return true;
      break;

    case "RABU":
      if (lastDateNumber === "2") return true;
      break;

    case "KAMIS":
      if (lastDateNumber === "8") return true;
      break;

    case "JUMAT":
      if (lastDateNumber === "7") return true;
      break;

    case "SABTU":
      if (lastDateNumber === "6") return true;
      break;

    case "MINGGU":
      if (lastDateNumber === "5") return true;
      break;

    default:
      return false;
  }

  return false;
}
